import { autoinject } from "aurelia-dependency-injection";
import { DialogController } from "aurelia-dialog";
import { bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Questionnaire } from "resources/classes/FhirModules/Questionnaire";
import { QuestionnaireResponse } from "resources/classes/FhirModules/QuestionnaireResponse";
import { Tools } from "resources/classes/FhirModules/Tools";
import { NitTools } from "resources/classes/NursitTools";
import { PatientItem } from "resources/classes/Patient/PatientItem";
import { ConfigService } from "resources/services/ConfigService";
import { QuestionnaireService } from "resources/services/QuestionnaireService";

// Todo: #-Funktion - soll Liste der TB zeigen im Textfeld

@autoinject
export class textblockDialog {
    model : ITextblockDialogueModel;
    
    /** gets or sets a value indicating whether the categories list should be displayed */
    hasCategories : boolean = false;

    /** the currently displayed textblocks, filtered from textblocksStorage by category */
    textblocks : ITextBlock[];
    
    /** backing store which holds all textblocks to get filtered per category to fill the textblocks array */
    textblocksStorage : ITextBlock[];
    
    /** the currently selected textblock object */
    selectedTextblock : ITextBlock;

    /** the textarea to display he resulting text to the user */
    resultText : HTMLTextAreaElement;
    
    /** indicator to warn if a field has an empty value (todo) */
    fieldValuesAreMissing : boolean = false;

    /** indicator that one or more of the referenced fields could not be found */
    fieldsAreMissing : boolean = false;

    /** the list of referenced fields that could not be resolved */
    missingFields : string[] = [];

    /** the list of categories, parsed from the textblocks-extension */
    categories : string[] = [];

    /** the currently selected category */
    selectedCategory : string;

    wouldExceedMaxLength : boolean = false;

    /** the resulting textblocks */
    @bindable effectiveTextblock : ITextBlock;

    /** the final result which is displayed in the resultText */
    @bindable result : string;

    /**
     * Initializes a new instance of the textblockDialog class
     * @param controller the DialogController class to use (injected by autoinject)
     * @param i18n the I18N to use (injected by autoinject)
     */
    constructor (protected controller: DialogController, protected  i18n: I18N) {                
    }

    /**
     * Is raised, whenever this dialogue is displayed
     * @param model
     */
    activate(model : ITextblockDialogueModel) {
        this.model = model;

        this.readTextBlockFromQuestionnaire(this.model.questionnaire);

        if (ConfigService.Debug) 
            window["textblocks"] = this;
    }

    resultChanged() {
        if (this.model?.questionnaireItem?.maxLength && this.model.responseItem) {
            let tempText = QuestionnaireResponse.GetResponseItemValue(this.model.responseItem, '');
            const resultLength = tempText.length + this.result.length + 1;
            this.wouldExceedMaxLength =  resultLength > this.model?.questionnaireItem?.maxLength;
            // console.warn(`check for maxLength: ${this.model?.questionnaireItem?.maxLength} (result is ${resultLength} chars) => Would exceed: ${this.wouldExceedMaxLength}`);
        } else {
            this.wouldExceedMaxLength = false;
        }
    }

    /** 
    * selects the current category or grouping of the textblocks to display and filters the textblocks for this category
    */
    selectCategory(newCat : string) {
        this.selectedCategory = newCat;
        this.textblocks = this.textblocksStorage.filter(o=>o.category === newCat 
            && (o.forFields.indexOf('*') > -1 || o.forFields.indexOf(this.model.questionnaireItem?.linkId) > -1));
    }

    /**
     * Adds or replaces the resulting text to the current reponse, triggered by the submit button of the dialogue
     * @param textMode the mode to add or replace text. when "addText" it will add the resulting text to the current value, everything else will replace the complete text
     */
    commit(textMode : string) {
        let text = this.resultText.value;
        if (textMode === 'addText' && this.model.responseItem.answer?.[0]?.valueString) {
            let oldText = String(this.model.responseItem.answer?.[0]?.valueString).trim();
            text = oldText + " "  + text;
        }

        if (this.wouldExceedMaxLength) {
            const maxLength = this.model?.questionnaireItem?.maxLength;
            if (maxLength) {
                text = text.substring(0, maxLength);
            }
        }

        this.model.responseItem.answer = [{
            valueString: text
        }];

        this.controller.cancel(false);
    }

    /**
     * gets the variables from the provided text-property which are specified by leading and closing bracket. Example. [[NIT_SVAn_104]] -> NIT_SVAn_104
     * @param text the text to parse for variables
     * @returns a string-array containing the variable names
     */
    getVariables(text : string) : string[] {
        // Alternative syntax using RegExp constructor
        // const regex = new RegExp('\\[\\[([^\\]]+)\\]\\]', 'g')
        const result = [];

        const regex = new RegExp('\\[\\[([^\\]]+)\\]\\]', 'gm')
        // const str = `Child 3: [[NIT_SVAn_104.Display]]. Mehr [[NIT_SVAn_104.Display]] hinter Child 3`;
        let m;

        while ((m = regex.exec(text)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            
            // The result can be accessed through the `m`-variable.
            m.forEach((match, _) => {
                if (match.indexOf('[[') === -1)
                    result.push(match);
            });
        }

        return result;
    }

    /** selects the resulting textblock from the displayed TB-list and replaces the variables with the resulting text */
    selectEffectiveTextblock(textblock : ITextBlock) {        
        this.fieldValuesAreMissing = false;
        this.fieldsAreMissing = false;
        this.missingFields = [];

        this.result = undefined;
        if (this.effectiveTextblock)
            this.effectiveTextblock.isSelected = false;

        this.effectiveTextblock = textblock;

        if (this.effectiveTextblock) {            
            this.result = this.effectiveTextblock.display;
            this.effectiveTextblock.isSelected = true;

            const variables = this.getVariables(this.effectiveTextblock.display); // get the variables from the text
            for (const varName of variables) { // and replace the variables with the resulting values
                // clean the variable by replacing .Display or .Value from it as it is not supposed to exist
                let linkPath : string = varName.split('.').filter(o=>o !== 'Display' && o != "Value").join('.');
                let additionalSettings; // the settings from the additional (Key="Value") properties from the variable
                let responseValueItem; // get the response item that has the answers
                const replaceTextArray : string[] = []; // will hold the display of the answers

                // now filter out the (title="Was auch immer") to get a clean path
                if (linkPath.indexOf('(') > -1 && linkPath.indexOf(')') > -1) {
                    additionalSettings = {};
                    const arr = linkPath.split('(');
                    linkPath = arr[0];  // restore linkPath;
                    for (let i = 1; i < arr.length; i++) {
                        const s = arr[i].substring(0, arr[i].length -1);
                        if (s.indexOf('=') == -1) continue;
                        
                        let [key, value] = s.split('=');
                        if (key && value?.length > 2) {
                            value = value.substring(1, value.length -1);

                            additionalSettings[key.toLowerCase()] = value;
                        }
                    }
                }

                // get the item to read the response-answer(s) from into responseValueItem
                if (linkPath.indexOf('.') === -1) {
                    // no "." in the variable indicates a local value from the current response
                    responseValueItem = QuestionnaireResponse.GetResponseItemByLinkId(this.model.response, linkPath, false);
                } else {
                    // ok we found a dot, so get the the latest response for the given questionnaire
                    const [qName, field] = linkPath.split('.');
                    const localResponse = QuestionnaireService.GetLatestResponseOfName(PatientItem.LastLoadedPatient, qName);
                    
                    if (localResponse) {
                        responseValueItem = QuestionnaireResponse.GetResponseItemByLinkId(localResponse, field, false);
                    }
                }

                // when the given variable could not be resolved then the responseValueItem is undefined
                if (!responseValueItem) {
                    console.warn(`TargetItem with linkId "${linkPath}" not found`);
                    this.fieldsAreMissing = true;
                    this.missingFields.push(linkPath);

                    responseValueItem = {};
                }

                // only when the field is visible and has answers
                if (Tools.IsEnableWhenSatisfied(this.model.questionnaireItem, this.model.response) // check for visibility
                        && NitTools.IsArray(responseValueItem?.answer)) {
                    for (const answer of responseValueItem.answer) {
                        const display = QuestionnaireResponse.GetResponseAnswerDisplay(answer);
                        if (display && display !== 'undefined' && display !== 'null') {
                            replaceTextArray.push(display.trim());
                        }
                    }
                } else {
                    this.fieldValuesAreMissing = true;
                }

                // make a neat string from the answer
                let replaceText = replaceTextArray.filter(o=>o).join(', ').trim();

                // when text exists add pre- and post-text when appicable (additionalSettings exists)
                if (replaceText && additionalSettings) {
                    if (additionalSettings?.title) {
                        replaceText = `${additionalSettings.title}${replaceText}`;
                    }

                    if (additionalSettings?.post) {
                        replaceText = `${replaceText}${additionalSettings.post}`;
                    }
                }

                // this is the final result
                this.result = this.result.replace(`[[${varName}]]`, replaceText);
            }
        }
    }

    selectTextBlock(textblock : ITextBlock) {
        if (this.selectedTextblock)
            this.selectedTextblock.isSelected = false;

        this.selectedTextblock = textblock;
        this.selectedTextblock.isSelected = true;

        this.selectEffectiveTextblock(textblock.isGroup ? undefined : textblock);
    }

    public readTextBlockFromQuestionnaire(questionnaire) {
        this.textblocks = this.textblocksStorage = [];
        this.categories = [];
        this.hasCategories = false;

        const rootElement = questionnaire?.extension?.find(o=>o.url?.endsWith('/textblock-definition'));
        if (!rootElement) {
            console.warn('No Textblock root element found!');
            this.controller.cancel();
        
            return;
        }

        if (!rootElement.valueCodeableConcept?.coding) {
            console.warn('No Textblock Elements!');
            this.controller.cancel();
        
            return;
        }

        // load all text blocks from extension
        for (const coding of  rootElement.valueCodeableConcept?.coding?.filter(o=>o.system?.endsWith('/textblock'))) {
            if (!coding.display) continue;
            this.readTextBlockFromCoding(coding);
        }

        // mark empty categories
        for (let i = 0; i < this.categories.length; i++) {
            if (this.textblocksStorage.filter(o=>o.category === this.categories[i]).length === 0) {
                this.categories[i] = '__EMPTY__';
            }
        }

        // remove empty categories from list
        this.categories = this.categories.filter(o=> o !== '__EMPTY__');

        // select the 1st category aviable
        this.selectCategory(this.categories[0]||'');

        // when only 1 category, it is the default category, so no list needed to be displayed
        this.hasCategories = this.selectedCategory && this.categories.length > 1;
    }

    private readTextBlockFromCoding(coding) {
        try {
            // parse the current TB
            const tb : ITextBlock = { forFields: [], code: String(coding.code).trim(), display: String(coding.display).trim(), isGroup: false, isSelected : false, category:this.categories[0] };
            if (tb.display) // move from "\\n" to \n
                tb.display = tb.display.replace(/\\n/g, '\n');
            /*
            "display": "{{NIT_SVAn_900_01_01|Psych-Krankheiten}}Der Patient ist Manisch-Depressiv",
                        "code": "Manisch-Depressiv"
            */
            if (/^{{.*}}/.test(tb.display)) { // -> "{{NIT_SVAn_900_01_01|Psych-Krankheiten}}Der Patient ist Manisch-Depressiv"
                let itemDisplay = tb.display.substring(2); // -> "NIT_SVAn_900_01_01|Psych-Krankheiten}}Der Patient ist Manisch-Depressiv"
                const header : string = itemDisplay.split('}}')[0]; // -> NIT_SVAn_900_01_01|Psych-Krankheiten
                tb.display = itemDisplay.split('}}')[1];
                const [linkIds,category] = header.split('|'); // -> linkIds="NIT_SVAn_900_01_01", category="Psych-Krankheiten"

                tb.category = category || this.i18n.tr("textblock_default_category");
                // add category if not already existent
                if (this.categories.indexOf(tb.category) === -1) {
                    this.categories.push(tb.category);
                }

                if (linkIds) 
                    tb.forFields = linkIds.split(',');
            }

            // only add textblocks that are specific for this field
            if (tb.forFields.indexOf(this.model.questionnaireItem.linkId) > -1) {
                this.textblocksStorage.push(tb);
            }

            if (/\[\[.*\]\]/.test(tb.display)) {
                tb.html = tb.display;
                for (const varName of this.getVariables(tb.display)) {
                    let effectiveQuestionnaire = this.model.questionnaire;
                    let effectiveQuestionnaireName: string = '';
                    let effectiveVarName = varName;

                    if (varName.indexOf('.') > -1) {
                        const [qName, _varName] = varName.split('.');
                        effectiveVarName = _varName;
                        effectiveQuestionnaireName = qName;
                        effectiveQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(qName);
                    }

                    const qElement = Questionnaire.GetQuestionnaireItemByLinkId(effectiveQuestionnaire, effectiveVarName);
                    if (qElement?.text) {
                        if (effectiveQuestionnaireName)
                            effectiveQuestionnaireName += '.';

                        tb.html = tb.html.replace(`[[${varName}]]`, `<span style="color: red; font-weight: bold">{</span><span style="font-weight: bold">${effectiveQuestionnaireName}${qElement.text}</span><span style="color: red; font-weight: bold">}</span>`);
                    }
                }
            }

            tb.html = (tb.html || tb.display).replace(/\n/g, '<br />\n');
        }
        catch(ex) {
            console.warn(ex);
        }

        return undefined;
    }
}

export interface ITextblockDialogueModel { 
    questionnaire, 
    questionnaireItem, 
    responseItem, 
    response,
    formElement
}

export interface ITextBlock {
    code : string;
    display: string;
    children? : ITextBlock[];
    isGroup : boolean;
    isSelected : boolean;
    forFields? : string[];
    category? : string;
    html? : string;
}
