import {bindable, inject, TaskQueue} from "aurelia-framework";
import {IQuestionnaireDialogSettings} from "./questionnaire-dialog-container";
import {NitTools} from "../../classes/NursitTools";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {ValidationController, ValidationControllerFactory} from 'aurelia-validation';
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {ConfigService} from "../../services/ConfigService";
import {CiwQuestionnaireItem} from "./ciw-questionnaire-item";
import {FhirService} from "../../services/FhirService";

@inject(TaskQueue, ValidationControllerFactory)
export class Questionnaire {
    @bindable questionnaire: any;
    @bindable response: any;
    @bindable previousresponse: any;
    @bindable showTrashcan: boolean;
    @bindable readonly: boolean;
    @bindable tooold: boolean;
    @bindable encounter;
    @bindable refclicked;
    @bindable toooldtext;
    @bindable grouplist: boolean | string = true;
    @bindable calculated: Function = undefined;
    @bindable showpkms: boolean;
    @bindable pkmssum: number = 0;
    @bindable change: Function;
    @bindable showgroupheaderbuttons: boolean;
    @bindable showtargetbuttons: boolean;
    @bindable noproblemtext: string;
    @bindable hidegroupinfo: boolean;
    @bindable patient: PatientItem;
    @bindable designing: boolean;
    @bindable responseBackup: any[];
    @bindable responseAssigned: Function;
    @bindable onVisibleGroupsChanged : Function;

    @bindable hidePlaceholders: boolean;
    controller: ValidationController;
    taskQueue: TaskQueue;
    item: CiwQuestionnaireItem[];
    groups: any[] = [];
    visibleGroups: number = -1;
    showNoPatientProblems: boolean = false;
    calcFields: CiwQuestionnaireItem[] = [];
    resizeHandler: number = undefined;
    
    /** the name of the group containing the "erhöhter Pflegebedarf", which should be displayed only dynamically */
    pkmsGroupName: string = ConfigService.PkmsGroupName;
    hasChanges: boolean;
    currentFormId: string = "formId";
    static staAssessmentId: string;
    static qList: IQuestionnaireList = undefined;
    public preFieldCalculationFunction: any;

    constructor(taskQueue: TaskQueue, validationControllerFactory: ValidationControllerFactory) {
        this.taskQueue = taskQueue;

        if (ConfigService.Debug) {
            window["questionnaire"] = this;
        }
    }

    async attached() {
        this.hidePlaceholders = RuntimeInfo.Features.hidePlaceholders === true;
        this.currentFormId = NitTools.UidName();

        if (document.body.clientWidth < 400) {
            this.grouplist = false;
        }

        Questionnaire.staAssessmentId = (await QuestionnaireService.GetQuestionnaireIds()).QAssessmentId;

        this.afterResponseAssigned();

        window.setTimeout(() => {
            this.resizeHandler = window.setInterval(this.formResize, 250);
            // $(window).on("resize", "#groupListParent", this.formResize);
            window.setTimeout(this.formResize, 250);
        }, 500);
    }

    detached() {
        window.clearInterval(this.resizeHandler);

        let $groups = $(".questionnaire-group");
        $groups.off("hidden.bs.collapse");
        $groups.off("shown.bs.collapse");
    }

    /***
     * this is executed, when the Questionnaire is displayed in a dialog
     * @param settings
     */
    activate(settings?: IQuestionnaireDialogSettings) {
        if (settings) {
            this.grouplist = settings.grouplist;
            this.response = settings.response;
            if (!this.questionnaire) {
                this.questionnaire = settings.questionnaire;
            }

            this.encounter = settings.encounter;
            this.tooold = settings.tooold;
            this.item = <CiwQuestionnaireItem[]>this.questionnaire.item;
        }
    }

    formResize() {
        let groupList = <HTMLDivElement>document.getElementById("groupListParent");
        if (groupList) {
            let h = window.innerHeight - 225;
            let newHeight = `${h}px`;
            let currentHeight = groupList.style.height;
            // compare the two css strings and bail out to avoid changes to the DOM when not needed!
            if (newHeight === currentHeight) return;

            groupList.style.height = newHeight;
        }
    }

    /***
     * When the patient of the questionnaire has been changed, ie when running embedded and calling another encounter without changing the view
     * @param newPatient
     */
    async patientChanged(newPatient: PatientItem) {
        if (newPatient) {
            await ConfigService.LoadConfigOverride(newPatient.ward, newPatient);
            const assessmentConfig = ConfigService.GetFormSettings('assessment');
            if (assessmentConfig && assessmentConfig.questionnaireName) {
                const q = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentConfig.questionnaireName);
                if (q) {
                    Questionnaire.staAssessmentId = q.id;
                }
            }

            this.checkEnableWhenProcessing();
        }
    }

    showpkmsChanged() {
        if (this.item) {
            let $pkmsGroup = $(`#${this.pkmsGroupName}`);
            if ($pkmsGroup.length === 0) $pkmsGroup = $(`.questionnaire-group#PKMS`);
            let tmp: any[] = [];
            if (this.showpkms) {
                tmp = this.item.filter(o => o.type === "group");
                $pkmsGroup.addClass("in");
            } else {
                tmp = this.item.filter(o => o.type === "group" && o.linkId !== "PKMS");
                $pkmsGroup.removeClass("in");
            }

            this.groups = [];
            tmp.forEach(g => {
                if (!g.extension) this.groups.push(g);
                else {
                    if (!g.extension.find(o => o.url.endsWith("questionnaire-hidden"))) {
                        this.groups.push(g);
                    }
                }
            });
        }
    };

    grouplistChanged(newValue: boolean | string) {
        if (typeof newValue === "string") {
            newValue = (<string>newValue).indexOf("t") === 0;
            this.grouplist = newValue;
        }
    }

    /** when the display of a group is toggled */
    groupDisplayToggled() {
        this.checkVisibleGroups();
    }

    checkVisibleGroups() {
        if (!this.grouplist) return;
        let visibleExtensions = NitTools.IsArray(this.response?.extension) ? this.response.extension.filter(o => o.url?.indexOf('questionnaire-group-visible') > -1) : [];
        // if no extension, or no extension pointing to questionnaire-group-visible
        if (this.response) {
            if (!this.response.extension || visibleExtensions.length === 0) {
                this.showNoPatientProblems = false;
            } else {
                // extensions found and questionnaire-group-visible for groups found too:
                let visible = visibleExtensions.filter(o => o.valueBoolean === true);
                this.visibleGroups = visible.length; // $(".questionnaire-main .questionnaire-group:visible").length;
                this.showNoPatientProblems = this.visibleGroups === 0;

                let ext = Fhir.Tools.GetOrCreateExtension(this.response, "questionnaire-visible-group-count", true);
                ext.valueInteger = this.visibleGroups;
            }
        }
    }

    /* getCalcObject(): object {
        let calcObject = {};

        if (this.response && this.response.item) {
            this.response.item.forEach(itm => {
                this.getCalcValues(itm, calcObject);
            });
        }

        return calcObject;
    } */

    changedField;
    onValueChanged($item) {
        // do a tripple calculation to ensure correct values because they may be dependend on each other
        this.calculateFieldValues();
        this.calculateFieldValues();
        this.calculateFieldValues();

        this.changedField = $item;

        // do this after an ensured refresh
        this.taskQueue.queueTask(() => {
            if (typeof this.response !== "undefined" && typeof this.change === "function") {
                this.change(this);
                /*  this seems to block the calculation. See #620
                let oldItem = QuestionnaireResponse.GetResponseItemByLinkId(this.responseBackup, $item.linkId);
                this.hasChanges = Fhir.Tools.ResponseItemValuesDiffer(oldItem, $item);

                if (this.hasChanges) {
                    this.change(this);
                } */
            }
        });
    }

    responseChanged(newResponse) {
        if (this.response) {
            Fhir.Questionnaire.EnsureStructuredResponse(this.questionnaire, this.response);
            this.assignDefaultValueIfEmpty();

            this.calculateFieldValues();

            this.checkVisibleGroups();
        }

        this.afterResponseAssigned();
        this.checkEnableWhenProcessing();
    }

    assignDefaultValueIfEmpty() {
        if (!this.response || this.response.status !== 'in-progress') return;

        const allLinkIds = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire, false);
        for (const linkId of allLinkIds)
            this.assignDefaultValueToItemIfEmpty(linkId);
    }

    assignDefaultValueToItemIfEmpty(linkId: string) {
        if (!linkId || !this.response || !this.questionnaire) return;

        const responseItem: any = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, linkId);
        if (!responseItem) return; // no item found
        if (typeof responseItem.answer !== "undefined" && responseItem.answer.length > 0) // or item already has an answer
        {
            const currentValue = Fhir.QuestionnaireResponse.GetResponseItemValue(responseItem);
            if (typeof currentValue !== "undefined")
                return;
        }

        const questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);
        if (!questionnaireItem)
            return;

        const initialKey = Object.keys(questionnaireItem).find(o => o.indexOf('initial') > -1);

        if (initialKey) {
            const initialObject = questionnaireItem[initialKey];
            if (typeof initialObject !== "undefined") {
                if (FhirService.FhirVersion >= 4) {
                    responseItem.answer = NitTools.Clone(initialObject);
                } else {
                    switch (initialKey) {
                        case "initialString":
                            if (String(initialObject).trim().indexOf('=') === 0) return; // don't update with old calculated field formulas
                            responseItem.answer = [{valueString: String(initialObject)}];
                            break;
                        case "initialCoding":
                            if (String(initialObject.code).trim().indexOf('=') === 0) return; // don't update with old calculated field formulas
                            responseItem.answer = [{valueCoding: initialObject}];
                            // for cosmetics update the display value from options if not present in the initialCoding
                            if (!responseItem.answer[0].valueCoding.display) {
                                if (questionnaireItem.option) {
                                    const option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === responseItem.answer[0].valueCoding.code);
                                    if (option && option.valueCoding.display) {
                                        responseItem.answer[0].valueCoding.display = option.valueCoding.display;
                                    }
                                }
                            }
                            break;
                        case "initialBoolean":
                            responseItem.answer = [{valueBoolean: NitTools.ParseBool(initialObject)}];
                            break;
                        case "initialDate":
                            responseItem.answer = [{valueDate: String(initialObject)}];
                            break;
                        case "initialQuantity":
                            responseItem.answer = [{valueQuantity: initialObject}];
                            break;
                        case "initialAttachment":
                            responseItem.answer = [{valueAttachment: initialObject}];
                            break;
                        case "initialUri":
                            responseItem.answer = [{valueUri: String(initialObject)}];
                            break;
                        case "initialTime":
                            responseItem.answer = [{valueTime: String(initialObject)}];
                            break;
                        case "initialInteger":
                            responseItem.answer = [{valueInteger: parseInt(String(initialObject))}];
                            break;
                        case "initialDateTime":
                            responseItem.answer = [{valueDateTime: String(initialObject)}];
                            break;
                        case "initialDecimal":
                            responseItem.answer = [{valueDecimal: parseFloat(String(initialObject))}];
                            break;
                    }
                }
            }
        }
    }

    isAssessment : boolean = false;
    hasTextBlocks : boolean = false;
    questionnaireChanged(newVal: any) {
        this.hasTextBlocks = !!(newVal?.extension?.find(o=>o.url?.endsWith('/textblock-definition')));
        // console.warn("questionnaire: hasTextBlocks: ", this.hasTextBlocks);

        this.calcFields = [];

        if (!newVal) {
            this.item = [];
            return;
        }

        const cfg = ConfigService.GetFormSettingsByQuestionnaireName(newVal.name);
        if (cfg) {
            if (cfg.settings) {
                if (typeof cfg.settings["showTargets"] === "boolean") {
                    this.showtargetbuttons = cfg.settings["showTargets"];
                }
            }

            this.isAssessment = cfg.route?.toUpperCase() === "ASSESSMENT";
        }

        this.item = <CiwQuestionnaireItem[]>newVal.item;

        if (this.item) {
            this.groups = this.item.filter(o => o.type === "group");

            this.item.forEach(itm => {
                this.getCalcField(itm);
            });
        }

        this.calculateFieldValues();

        if (this.taskQueue)
            this.taskQueue.queueTask(() => {
                /*
                // attached to toggling the groups
                let $groups = $(".questionnaire-group");
                $groups.off("hidden.bs.collapse");
                $groups.on("hidden.bs.collapse", () => {
                    this.groupDisplayToggled();
                });

                $groups.off("shown.bs.collapse");
                $groups.on("shown.bs.collapse", () => {
                    this.groupDisplayToggled();
                });

                // console.debug('Questionnaire', 'NO GROUP Element found. So not displaying infopanel');
                /* let count = document.querySelectorAll(".questionnaire-group.in").length;
                this.showNoPatientProblems = count === 0; */
                this.checkVisibleGroups();
            });

        this.checkEnableWhenProcessing();
    }

    public enableProcessEnableWhenChanged : boolean = false;
    checkEnableWhenProcessing() {
        if (this.response && this.questionnaire && this.patient) {
            this.taskQueue.queueTask(() => {
                this.enableProcessEnableWhenChanged = true;
            });
        } else {
            this.enableProcessEnableWhenChanged = false;
        }
    }

    /**
     * This is the event handler when the user checked/unchecked a group in the group list
     * @param $event the QuestionnaireItem (here:group) that has been changed
     */
    groupVisibilityChanged($event) {
        this.checkVisibleGroups();
        if (typeof this.onVisibleGroupsChanged === "function")
            this.onVisibleGroupsChanged($event);
    }

    calculateFieldValues() {
        if (!this.questionnaire) return;

        if (typeof this.preFieldCalculationFunction === "function")
            this.preFieldCalculationFunction(this.response);

        if (!this.calcFields || this.calcFields.length === 0) {
            for (const item1 of this.questionnaire.item) {
                item1 && this.getCalcField(<CiwQuestionnaireItem>item1);
            }
        }

        if (!this.calcFields || this.calcFields.length === 0 || !this.response || !this.response.item) return;

        for (const questionnaireItem of this.calcFields) {
            let result = Fhir.Tools.CalculateField(questionnaireItem, this.questionnaire, this.response);
            let responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, questionnaireItem.linkId, true);

            if (responseItem) {
                if (questionnaireItem.type === 'string' || questionnaireItem.type === 'text') {
                    responseItem.answer = [{ valueString: (result||"").toLocaleString() }];
                } else if (questionnaireItem.type === 'integer') {
                    let int = parseInt(String(result));
                    responseItem.answer = [{ valueInteger: int }];
                } else if (questionnaireItem.type === 'decimal') {
                    let flt = parseFloat(String(result));
                    responseItem.answer = [{ valueDecimal: flt }];
                } else if (questionnaireItem.type === 'boolean') {
                    let bool = NitTools.ParseBool(result);
                    responseItem.answer = [{ valueBoolean: bool }];
                }
            }

            if (typeof this.calculated === "function") {
                this.calculated(this);
            }
        }
    }

    getCalcField(questionnaireItem: CiwQuestionnaireItem) {
        try {
            if (questionnaireItem) {
                if (questionnaireItem.item) {
                    questionnaireItem.item.forEach(itm => {
                        this.getCalcField(itm);
                    });
                }

                if (questionnaireItem.extension) {
                    let extCalc = questionnaireItem.extension.find(o => o.url.endsWith('questionnaire-calculated-field') && o.valueString);
                    if (extCalc) {
                        let str = extCalc.valueString;
                        if (str.indexOf('=') !== 0) {
                            str = "=" + str;
                        }

                        questionnaireItem.initialCoding = {
                            code: str
                        };
                    }
                }

                if (questionnaireItem.initialCoding
                    && questionnaireItem.initialCoding.code) {
                    let code = questionnaireItem.initialCoding.code.trim();
                    if (code.indexOf("=") === 0) {
                        this.calcFields.push(questionnaireItem);
                    }
                }
            }
        } catch (e) {
            console.warn("in questionnaire.ts::getCalcField():\n" + e.message || JSON.stringify(e), "Param:", questionnaireItem);
        }
    }

/*
    getCalcValues(responseItem: any, calcObject: any) {
        if (responseItem) {
            if (responseItem.item) {
                responseItem.item.forEach(itm => {
                    this.getCalcValues(itm, calcObject);
                })
            }

            if (responseItem.answer
                && responseItem.answer.length > 0) {
                const vars = [];
                const displays = [];
                const codes = [];
                for (const answer of responseItem.answer) {
                    let value = Fhir.QuestionnaireResponse.GetResponseAnswerValue(answer);
                    if (value) {
                        vars.push(value);
                    }
                    if (answer.valueCoding?.display) {
                        displays.push(answer.valueCoding.display);
                    }
                    if (answer.valueCoding?.code) {
                        codes.push(answer.valueCoding.code);
                    }
                }

                calcObject[`VAR_${responseItem.linkId}`] = vars.join(',');
                calcObject[`DISPLAY_${responseItem.linkId}`] = displays.join(',');
                calcObject[`CODE_${responseItem.linkId}`] = codes.join(',');
            }
        }
    }
*/
    get assessmentId(): string {
        return Questionnaire.staAssessmentId;
    }

    private afterResponseAssigned() {
        if (typeof this.responseAssigned === "function") {
            this.responseAssigned(this.response);
        }
    }
}
